.PropertyCard {
  display: block;

  .card {
    .card-img-top {
      height: 140px;
      object-fit: cover;
      object-position: 100% 0%;
    }
  }
}
