.modal-container {
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    padding-top: 90px;
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

    .modal-body {
      display: block;

      .modal-img {
        max-width: 60%;
        max-height: calc(100vh - 125px);
        border-radius: 5px;
      }

      .modal-btn {
        margin-top: -70px;
      }
    }
}


