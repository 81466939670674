.SingleProperty {
  display: block;

  .card-img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    object-position: 50% 0%;
  }

  .card-text {
    max-width: 300px;
  }

  #map-container {
    width: 500px;
    height: 500px;
  }

  .btn-responsive {
    /* matches 'btn-md' */
    opacity: 0.9;
    position: absolute;
    margin-top: -35px;
    margin-left: 64px;
  }
}
