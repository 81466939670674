@import url(https://fonts.googleapis.com/css2?family=Suez+One&display=swap);
body{background-color:#fff}

.App{text-align:center}.App h3{margin:8px;font-family:'Suez One', serif}

.MyNavbar{display:block}.MyNavbar .navbar-brand{font-size:x-large;font-family:"Suez One",serif}.MyNavbar .navbar-brand .pf-logo{height:32px;margin-top:-4px;margin-right:1px;pointer-events:none}

.Auth{text-align:center}.Auth footer{position:fixed;width:100%;bottom:0}

.PropertyCard{display:block}.PropertyCard .card .card-img-top{height:140px;object-fit:cover;object-position:100% 0%}

.Home{display:block}

.MyMap .leaflet-container{border:solid 5px yellow;width:100%;height:600px;z-index:0}

.EditProperty{display:block}

.EditPin{display:block}

.NewProperty{display:block}

.NewPin{display:block}

.Pins{display:block}

.modal-container{position:fixed;z-index:2;left:0;top:0;width:100%;height:100%;padding-top:90px;overflow:hidden;background-color:#000;background-color:rgba(0,0,0,0.4)}.modal-container .modal-body{display:block}.modal-container .modal-body .modal-img{max-width:60%;max-height:calc(100vh - 125px);border-radius:5px}.modal-container .modal-body .modal-btn{margin-top:-70px}

.SingleProperty{display:block}.SingleProperty .card-img{width:200px;height:150px;object-fit:cover;object-position:50% 0%}.SingleProperty .card-text{max-width:300px}.SingleProperty #map-container{width:500px;height:500px}.SingleProperty .btn-responsive{opacity:0.9;position:absolute;margin-top:-35px;margin-left:64px}

.SinglePin{display:block}.SinglePin .card-img{width:200px;height:150px;object-fit:cover;object-position:50% 0%}.SinglePin .card-text{max-width:300px}.SinglePin #map-container{width:500px;height:500px}.SinglePin .btn-responsive{opacity:0.9;position:absolute;margin-top:-35px;margin-left:64px}

